import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
//import axios from 'axios'

const app = createApp(App);
app.use(router);
app.mount('#app');


/*
app.config.globalProperties.$axios = axios

app.mount('#app')

console.log("Iniciando GET")
axios.get('http://localhost:3000/')
  .then(response => {
    console.log("Respuesta del GET")
    console.log(response.data)
  })
  .catch(error => {
    console.error("Error en la solicitud GET:", error)
  })
*/