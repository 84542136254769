<template>

<section>

    {{ items }}
    <!--Swiper-->
    <div class="swiper-container swiper-slider swiper-slider-1">
        <div class="swiper-wrapper">

            <div class="swiper-slide" data-slide-bg="images/slide-1.jpg">
                <div class="swiper-slide-caption section-xs">
                    <div class="container">
                        <div class="swiper-caption-wrap" data-caption-animate="fadeInDown">
                            <p class="h3" data-caption-animate="fadeIn" data-caption-delay="800">1530-2 Liberty Ave 4 
                            </p>
                            <hr data-caption-animate="fadeIn" data-caption-delay="800">
                            <p class="d-none d-md-block" data-caption-animate="fadeIn" data-caption-delay="800">This is
                                a 3 bedroom, 2.0 bathroom multi family home. It is located at 1530 Liberty Ave Hillside.
                            </p>
                            <div class="price text-ubold" data-caption-animate="fadeIn" data-caption-delay="800">
                                $309.00<span>/day</span></div><a class="btn btn-sm btn-sushi"
                                href="muestrapropiedad.php?Prop=1" data-caption-animate="fadeIn"
                                data-caption-delay="800">book now</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-slide" data-slide-bg="images/slide-2.jpg">
                <div class="swiper-slide-caption section-xs">
                    <div class="container">
                        <div class="swiper-caption-wrap" data-caption-animate="fadeInDown">
                            <p class="h3" data-caption-animate="fadeIn" data-caption-delay="800">53 Rachel Ct 53</p>
                            <hr data-caption-animate="fadeIn" data-caption-delay="800">
                            <p class="d-none d-md-block" data-caption-animate="fadeIn" data-caption-delay="800">A
                                renovated apartment for rent by owner. 2 bedroom, 2 full bath living and dining room</p>
                            <div class="price text-ubold" data-caption-animate="fadeIn" data-caption-delay="800">
                                $216.00<span>/day</span></div><a class="btn btn-sm btn-sushi"
                                href="muestrapropiedad.php?Prop=1" data-caption-animate="fadeIn"
                                data-caption-delay="800">book now</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-slide" data-slide-bg="images/slide-3.jpg">
                <div class="swiper-slide-caption section-xs">
                    <div class="container">
                        <div class="swiper-caption-wrap" data-caption-animate="fadeInDown">
                            <p class="h3" data-caption-animate="fadeIn" data-caption-delay="800">Modera Loft</p>
                            <hr data-caption-animate="fadeIn" data-caption-delay="800">
                            <p class="d-none d-md-block" data-caption-animate="fadeIn" data-caption-delay="800">Make
                                Modera Loft your home and reside in historic Jersey City style.</p>
                            <div class="price text-ubold" data-caption-animate="fadeIn" data-caption-delay="800">
                                $239.00<span>/day</span></div><a class="btn btn-sm btn-sushi"
                                href="muestrapropiedad.php?Prop=1" data-caption-animate="fadeIn"
                                data-caption-delay="800">book now</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Swiper Navigation-->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
</section>
<!-- Page Content-->
<main class="page-content">
    <!--Section Find your home-->
    <section class="section-md section-bottom-0 bg-gray-dark">
        <div class="container position-margin-top">
            <div class="search-form-wrap bg-default container-shadow">
                <h3>Filtrar Propiedades</h3>

                <form class="form-variant-1" action="buscador.php" name="search-form">

                    <div class="form-wrap">
                        <label class="form-label" for="select-search-2">Tipo Transacción</label>
                        <select class="form-input select-filter" id="select-search-2"
                            data-minimum-results-for-search="Infinity">
                            <option value="Cualquiera" selected>Cualquiera</option>
                            <option value="Vacant land">Arriendos</option>
                            <option value="Commercial">Ventas</option>
                        </select>
                    </div>

                    <div class="form-wrap">
                        <label class="form-label" for="select-search-3">Tipo de Propiedad</label>
                        <select class="form-input select-filter" id="select-search-3"
                            data-minimum-results-for-search="Infinity">
                            <option value="Cualquiera" selected>Cualquiera</option>
                            <option value="Under Construction">Casa Habitación</option>
                            <option value="Ready to Move">Bodega</option>
                            <option value="Ready to Move">Local Comercial</option>
                        </select>
                    </div>

                    <div class="form-wrap">
                        <label class="form-label" for="keyword">Que contenga</label>
                        <input class="form-input" id="keyword" type="text" name="keyword" placeholder="Texto...">
                    </div>

                    <div class="form-wrap">
                        <label class="form-label" for="select-search-1">Comuna</label>
                        <select class="form-input select-filter" id="select-search-1"
                            data-minimum-results-for-search="Infinity">
                            <option value="Cualquiera" selected>Cualquiera</option>
                            <option value="Denver">Arauco</option>
                            <option value="Houston">Curanilahue</option>
                            <option value="Las Vegas">Los Alamos</option>
                            <option value="Orlando">Cañete</option>
                            <option value="Phoenix">Contulmo</option>
                            <option value="San Diego">Tirúa</option>
                            <option value="Miami">Lota</option>
                            <option value="Atlanta">Purén</option>
                        </select>
                    </div>

                    <div class="form-wrap width-1"><span>Precio (Ch$)<br></span>
                        <div class="form-inline-flex-xs">
                            <input class="rd-range-input-value rd-range-input-value-1 form-input" id="range-1"
                                type="text" name="range-2"><span class="text-abbey dash">&mdash;</span>
                            <input class="rd-range-input-value rd-range-input-value-2 form-input" id="range-2"
                                type="text" name="range-3">
                        </div>
                        <div class="rd-range" data-min="100" data-max="10000" data-start="[600, 9500]" data-step="50"
                            data-tooltip="true" data-min-diff="100" data-input=".rd-range-input-value-1"
                            data-input-2=".rd-range-input-value-2"></div>
                    </div>

                    <div class="form-wrap width-1"><span>Area (M2)<br></span>
                        <div class="form-inline-flex-xs">
                            <input class="rd-range-input-value rd-range-input-value-3 form-input" id="range-3"
                                type="text" name="range-2"><span class="text-abbey dash">&mdash;</span>
                            <input class="rd-range-input-value rd-range-input-value-4 form-input" id="range-4"
                                type="text" name="range-3">
                        </div>
                        <div class="rd-range" data-min="500" data-max="22000" data-start="[2000, 20000]" data-step="50"
                            data-tooltip="true" data-min-diff="500" data-input=".rd-range-input-value-3"
                            data-input-2=".rd-range-input-value-4"></div>
                    </div>

                    <div class="form-wrap">
                        <label class="form-label" for="select-search-3">Ordenar por</label>
                        <select class="form-input select-filter" id="select-search-3"
                            data-minimum-results-for-search="Infinity">
                            <option value="Cualquiera" selected>Cualquiera</option>
                            <option value="Under Construction">Precio</option>
                            <option value="Ready to Move">Tipo Propiedad</option>
                            <option value="Ready to Move">Comuna</option>
                        </select>
                    </div>

                    <button class="btn btn-sm btn-sushi btn-min-width-sm">Buscar</button>

                </form>

            </div>
        </div>
    </section>

    <!--Section popular categories 1-->
    <section class="section-lg text-center text-md-start bg-gray-dark">
        <div class="container">
            <h2>Principales Tipos de Propiedades</h2>
            <hr>
            <div class="row offset-custom-11">
                <div class="col-12 col-md-6"><a class="img-thumbnail-variant-1" href="arriendoshabitacionales.php"><img
                            src="images/categories-1.jpg" alt="" width="570" height="380">
                        <div class="caption">
                            <h4 class="text-white">Arriendos Habitacionales</h4>
                            <p>54 propiedades</p>
                        </div>
                    </a></div>
                <div class="col-12 col-md-6"><a class="img-thumbnail-variant-1" href="ventashabitacionales.php"><img
                            src="images/categories-2.jpg" alt="" width="570" height="380">
                        <div class="caption">
                            <h4 class="text-white">Ventas Habitacionales</h4>
                            <p>36 propiedades</p>
                        </div>
                    </a></div>
                <div class="col-12 col-md-6"><a class="img-thumbnail-variant-1" href="arriendoscomerciales.php"><img
                            src="images/categories-3.jpg" alt="" width="570" height="380">
                        <div class="caption">
                            <h4 class="text-white">Arriendos Comerciales</h4>
                            <p>61 propiedades</p>
                        </div>
                    </a></div>
                <div class="col-12 col-md-6"><a class="img-thumbnail-variant-1" href="ventascomerciales.php"><img
                            src="images/categories-4.jpg" alt="" width="570" height="380">
                        <div class="caption">
                            <h4 class="text-white">Ventas Comerciales</h4>
                            <p>24 propiedades</p>
                        </div>
                    </a></div>
            </div>
        </div>
    </section>

</main>



</template>
  
<script>
import axios from 'axios';

export default {
  name: 'HomeSection',
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get('http://localhost:3000/')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
  
  <style scoped>
  /* Estilos específicos para el componente Nosotros */
  </style>
  