import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/HomeSection.vue';
import Nosotros from './components/NosotrosSection.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
      { path: '/', component: Home },
      { path: '/home', component: Home },
      { path: '/nosotros', component: Nosotros },
      { path: '/prueba', component: () => import('./components/RutaPrueba.vue') },
      // Otras rutas
    ]
  });

export default router;
