<template>
    <main class="page-content text-center text-md-start">
      <!-- Section Title Breadcrumbs-->
      <section class="section-full">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1>Nosotros</h1>
            </div>
          </div>
        </div>
      </section>
      <!--Section Terms of Service-->
      <section class="section-lg section-mod-1">
        <div class="container">
          <h3>QUIENES SOMOS</h3>
          <p align="justify">
            Somos profesionales Cañetinos, conocedores de nuestra zona y con el
            interés claro de orientar de la mejor forma posible su inversión
            inmobiliaria, ya sea a través de lograr el mejor arriendo a sus
            necesidades o la adquisición de la mejor propiedad de acuerdo a su
            interés
          </p>
          <p align="justify">
            El Equipo actual es liderado por su fundador y Administrador actual,
            Miguel González Ferrer, con una amplia experiencia adquirida a lo
            largo de los últimos 10 años en que se ha dedicado al tema.
          </p>
          <p align="justify">
            Si bien estamos ubicados en la comuna de Cañete, atendemos las siete
            comunas de la provincia.
          </p>
          <hr />
        </div>
      </section>
    </main>
  </template>
  
  <script>
  export default {
    name: 'NosotrosSection',
  };
  </script>
  
  <style scoped>
  /* Estilos específicos para el componente Nosotros */
  </style>
  