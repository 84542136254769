<template>
  <html lang="es">
    <head>
      <!-- meta tags -->
      <title>CORRETAJES ARAUCO</title>
      <meta name="description" content="CORRETAJES ARAUCO - Home">
      <meta name="Title" CONTENT="CORRETAJES ARAUCO">
      <meta name="Subject" CONTENT="Corretajes Arauco">
      <meta name="keywords" content="arriendo propiedades, venta propiedades, corretaje, provincia de arauco, cañete">
      <meta name="Language" CONTENT="spanish">
      <meta name="Revisit" CONTENT="1 day">
      <meta name="Distribution" CONTENT="Global">
      <meta name="Robots" CONTENT="All; FOLLOW">
      <meta name="Resource-type" CONTENT="Homepage">
      <meta name="author" content="fica.cl">

      <meta name="format-detection" content="telephone=no">
      <meta name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
      <meta http-equiv="X-UA-Compatible" content="IE=Edge">
      <meta charset="utf-8">

      <!-- favicon -->
      <link rel="icon" href="images/favicon.ico" type="image/x-icon">
      <link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon" />

      <link rel="made" href="mailto:alejandro@fica.cl">

      <!-- Stylesheets-->
      <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Lato:400,700,900,400italic">
      <link rel="stylesheet" href="css/bootstrap.css">
      <link rel="stylesheet" href="css/fonts.css">
      <link rel="stylesheet" href="css/style.css">
    </head>

    <body>
      <div class="preloader">
        <div class="preloader-body">
          <div class="cssload-container">
            <div class="cssload-speeding-wheel"></div>
          </div>
        </div>
      </div>
      <!-- Page-->
      <div class="page">
        <!-- Page Header-->
        <header class="page-head">
            <!-- RD Navbar-->
            <div class="rd-navbar-wrap header_corporate">
                <nav class="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
                    data-md-layout="rd-navbar-fullwidth" data-lg-layout="rd-navbar-fullwidth"
                    data-xl-layout="rd-navbar-fullwidth" data-xxl-layout="rd-navbar-fullwidth"
                    data-device-layout="rd-navbar-fixed" data-sm-device-layout="rd-navbar-fixed"
                    data-md-device-layout="rd-navbar-fixed" data-lg-device-layout="rd-navbar-fullwidth"
                    data-xl-device-layout="rd-navbar-fullwidth" data-xxl-device-layout="rd-navbar-fullwidth"
                    data-stick-up-offset="100px">
                    <!--RD Navbar Panel-->
                    <div class="rd-navbar-top-panel">
                        <div class="rd-navbar-top-panel-wrap">
                            <dl class="dl-horizontal-mod-1 login">
                                <dt><span class="mdi mdi-login icon-xxs-mod-2"></span></dt>
                                <dd><a class="text-sushi" href="{{ route('contact') }}">Contáctenos</a></dd>
                            </dl>
                            <div class="top-panel-inner">
                                <dl class="dl-horizontal-mod-1">
                                    <dt><span class="material-icons-local_phone icon-xxs-mod-2"></span></dt>
                                    <dd><a href="callto:+56 9 9917 2560">56 9 9917 2560</a></dd>
                                </dl>
                                <dl class="dl-horizontal-mod-1">
                                    <dt><span class="material-icons-drafts icon-xxs-mod-2"></span></dt>
                                    <dd><a href="mailto:info@corretajesarauco.cl"></a></dd>
                                </dl>
                                <address>
                                    <dl class="dl-horizontal-mod-1">
                                        <dt><span class="mdi mdi-map-marker-radius icon-xxs-mod-2"></span></dt>
                                        <dd><a class="inset-11" href="#">Orella N0. 232, Cañete, provincia de Arauco,
                                                Biobío - Chile</a></dd>
                                    </dl>
                                </address>
                            </div>
                            <ul class="list-inline">
                                <li><a class="fa-facebook" href="#"></a></li>
                                <li><a class="fa-linkedin" href="#"></a></li>
                                <li><a class="fa-instagram" href="#"></a></li>
                            </ul>
                            <div class="btn-group"><a class="btn btn-sm btn-primary"
                                    href="{{ route('contact') }}">Contáctenos</a></div>
                        </div>
                    </div>
                    <div class="rd-navbar-inner inner-wrap">
                        <div class="rd-navbar-panel">
                            <!-- RD Navbar Toggle-->
                            <button class="rd-navbar-toggle"
                                data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                            <!-- RD Navbar Brand-->
                            <div class="rd-navbar-brand"><a class="brand-name" href="/"><img
                                        src="images/logo-1.png" alt=""></a></div>
                        </div>
                        <div class="btn-group"><a class="btn btn-sm btn-primary"
                                href="{{ route('contact') }}">Contáctenos</a></div>
                        <div class="rd-navbar-nav-wrap">
                            <!-- RD Navbar Nav-->
                            <ul class="rd-navbar-nav">
                                <li class="{{ Request::is('/') ? 'active' : '' }}"><a
                                        href="/">Home</a></li>
                                <li class="{{ Request::is('nosotros') ? 'active' : '' }}"><a
                                        href="{{ route('nosotros') }}">Nosotros</a></li>
                                <li class="{{ Request::is('arriendos') ? 'active' : '' }}"><a
                                        href="{{ route('arriendos') }}">Arriendos</a>
                                    <!-- RD Navbar Dropdown-->
                                    <ul class="rd-navbar-dropdown">
                                        <li><a href="{{ route('arriendoshabitacionales') }}">Habitacionales</a></li>
                                        <li><a href="{{ route('arriendoscomerciales') }}">Comerciales</a></li>
                                    </ul>
                                </li>
                                <li class="{{ Request::is('ventas') ? 'active' : '' }}"><a
                                        href="{{ route('ventas') }}">Ventas</a>
                                    <!-- RD Navbar Dropdown-->
                                    <ul class="rd-navbar-dropdown">
                                        <li><a href="{{ route('ventashabitacionales') }}">Habitacionales</a></li>
                                        <li><a href="{{ route('ventascomerciales') }}">Comerciales</a></li>
                                        <li><a href="{{ route('ventasterrenos') }}">Terrenos</a></li>
                                    </ul>
                                </li>
                                <li class="{{ Request::is('galeria') ? 'active' : '' }}"><a
                                        href="{{ route('galeria') }}">En Imágenes</a></li>
                                <li class="{{ Request::is('avisosexternos') ? 'active' : '' }}"><a
                                        href="{{ route('avisosexternos') }}">Avisos Externos</a></li>
                                <li class="{{ Request::is('noticias') ? 'active' : '' }}"><a
                                        href="{{ route('noticias') }}">Noticias</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>

        <!-- Contenido para cada seccion -->

            <slot></slot>
        

        <!-- Page Footer-->
        <footer class="page-foot text-start bg-gray">
            <section class="footer-content">
                <div class="container">
                    <div class="row flow-offset-3">
                        <div class="col-12 col-md-6 col-xl-3">
                            <div class="rd-navbar-brand"><a class="brand-name" href="index.php"><img
                                        src="images/logo-2.png" alt=""></a></div>
                            <p>Ventas y arriendos de Propiedades en la provincia de Arauco.</p>
                            <address class="address">
                                <dl>
                                    <dt>Dirección:</dt>
                                    <dd>Orella N0. 232, Cañete, provincia de Arauco, Biobío - Chile</dd>
                                </dl>
                                <dl class="dl-horizontal-mod-1">
                                    <dt>Fono</dt>
                                    <dd><a class="text-primary" href="callto:#">56 9 9917 2560</a></dd>
                                </dl>
                                <dl class="dl-horizontal-mod-1">
                                    <dt>Whatsapp</dt>
                                    <dd><a class="text-primary" href="callto:#">56 9 9917 2560</a></dd>
                                </dl>
                                <dl class="dl-horizontal-mod-1">
                                    <dt>Email</dt>
                                    <dd><a class="text-primary" href="mailto:#">info@corretajesarauco.cl</a></dd>
                                </dl>
                            </address>
                        </div>
                        <div class="col-12 col-md-6 col-xl-3">
                            <h6 class="text-ubold">Sobre Propiedades</h6>
                            <ul class="list-marked well6 text-start">
                                <li><a href="arriendos.php">ARRIENDOS</a></li>
                                <li><a href="arriendoshabitacionales.php"> - Arriendo Habitacional</a></li>
                                <li><a href="arriendoscomerciales.php"> - Arriendo Comercial</a></li>
                                <li><a href="ventas.php">VENTAS</a></li>
                                <li><a href="ventashabitacionales.php"> - Venta Habitacional</a></li>
                                <li><a href="ventascomerciales.php"> - Venta Comercial</a></li>
                                <li><a href="galeria.php">Imágenes de Propiedades</a></li>
                                <li><a href="avisosexternos.php">Avisos Externos</a></li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-6 col-xl-3">
                            <h6 class="text-ubold">Últimas Noticias</h6>
                            <div class="blog-post text-start">
                                <div class="blog-post-title"><a class="text-primary" href="noticia.php?Noti=1">Believe
                                        in the Business of Your Dreams</a></div>
                                <div class="blog-post-time">
                                    <time class="small" datetime="2016">1 de Mayo de 2023</time>
                                </div>
                            </div>
                            <div class="blog-post text-start">
                                <div class="blog-post-title"><a class="text-primary"
                                        href="noticia.php?Noti=1">Establishing Your Brand on College Campuses</a></div>
                                <div class="blog-post-time">
                                    <time class="small" datetime="2016">1 de Mayo de 2023</time>
                                </div>
                            </div>
                            <div class="blog-post text-start">
                                <div class="blog-post-title"><a class="text-primary" href="noticia.php?Noti=1">Beautiful
                                        Rumi Quotes that are Worth Reading</a></div>
                                <div class="blog-post-time">
                                    <time class="small" datetime="2016">1 de Mayo de 2023</time>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-3">
                            <h6 class="text-ubold">Suscripción</h6>
                            <p class="text-gray">Ingrese su correo electrónico para recibir por email las nuevas
                                Propiedades que vayamos publicando y/o comercializando.</p>
                            <!-- RD Mailform-->
                            <!--
                            <form class="rd-mailform text-start subscribe"  method="post" action="#">
                                <div class="form-wrap">
                                    <label class="form-label" for="email-sub"></label>
                                    <input class="form-input" id="email-sub" type="email" name="email"
                                        data-constraints="Required Email" placeholder="Enter e-mail">
                                </div>
                                <button class="btn btn-sushi btn-sm">suscribirse</button>
                            </form>
                            -->
                        </div>
                    </div>
                </div>
            </section>
            <section class="copyright">
                <div class="container">
                    <div class="row row-30 justify-content-between">
                        <div class="col-md-auto">
                            <p>&#169;<span class="copyright-year">All Rights Reserved</span><a
                                    href="confidencialidad.php">Términos de Uso y Política de Privacidad</a></p>
                        </div>
                        <div class="col-md-auto">
                            <ul class="list-inline">
                                <li><a class="fa-facebook" href="#"></a></li>
                                <li><a class="fa-linkedin" href="#"></a></li>
                                <li><a class="fa-instagram" href="#"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Rd Mailform result field-->
            <div class="rd-mailform-validate"></div>
        </footer>
      </div>
      <!-- Global Mailform Output-->
      <div class="snackbars" id="form-output-global"></div>
    </body>
  </html>
</template>

<script>
export default {
  name: 'SiteLayout',
  mounted() {
    this.loadScript('/js/core.min.js', () => {
      this.loadScript('/js/script.js');
    });
  },
  methods: {
    loadScript(src, callback) {
      const script = document.createElement('script');
      script.src = this.getScriptPath(src);
      script.async = true;
      script.onload = callback; // Espera a que se cargue el script antes de llamar a la función de devolución de llamada
      document.body.appendChild(script);
    },
    getScriptPath(src) {
      return `${window.location.origin}${src}`;
    }
  }
};
</script>


<style scoped>
/* Estilos específicos para el componente Site */

.preloader {
  /* Estilos del preloader */
}

.page {
  /* Estilos de la página */
}

.page-head {
  /* Estilos del encabezado */
}

.page-foot {
  /* Estilos del pie de página */
}

.snackbars {
  /* Estilos de los snackbars */
}
</style>
