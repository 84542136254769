<template>
  <div id="app">
    <Site>
      <router-view />
    </Site>
  </div>
</template>

<script>

import Site from './components/SiteLayout.vue';

export default {
  name: 'App',
  components: {
    Site,
  },
};

</script>

<style>
/* Estilos globales */
</style>
